export default {
    methods:{
        formatDateText(date) {
            if (!date) return null;
            const [dates, hours] = date.split(" ");
            if (date.indexOf("-") > 0) {
              const [year, month, day] = dates.split("-");
              return `${day}/${month}/${year}`;
            }
            return date;
        }
    }
}