<template>
  <v-row class="px-5">
    <v-layout row wrap v-if="loading" align-center>
      <v-flex lg12 xs12 class="py-5 text-center">
        <v-progress-circular :indeterminate="true" cirlcular></v-progress-circular>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-else>
       <template v-if="propiedades.metodoPago==='BOLETA'">
        <v-flex xs12>
          <v-layout row wrap v-if="boletas.length>0">  
            <v-flex xs12 class="subheading font-weight-medium pb-0">Solicitud pasarela de pagos</v-flex>
            <v-flex lg3 xs6>
              <v-text-field label="Numero Boleta" :value="boletas[0].numero" disabled></v-text-field>
            </v-flex>
            <v-flex lg3 xs6>
              <v-text-field label="Fecha Pago Boleta" :value="boletas[0].fecha" disabled></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
       </template>
      <template v-if="propiedades.metodoPago==='PASARELA_PAGO' && solicitudPasarelaPago">
        <v-flex xs12 class="subheading font-weight-medium pb-0">Solicitud pasarela de pagos</v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Código Transaccion" :value="solicitudPasarelaPago.codigoTransaccion" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Estado" :value="solicitudPasarelaPago.estado" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Fecha Creacion" :value="formatDateText(solicitudPasarelaPago.fechaCreacion)" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Monto Total" :value="solicitudPasarelaPago.montoTotal" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Método Pago" :value="solicitudPasarelaPago.metodoPago" disabled></v-text-field>
        </v-flex>
      </template>
      <template v-if="propiedades.metodoPago==='CPT'||(propiedades.metodoPago==='PASARELA_PAGO' && solicitudPasarelaPago.metodoPago && solicitudPasarelaPago.metodoPago==='PPTE')">
        <v-flex xs12 class="subheading font-weight-medium pb-0">Código pago de trámite</v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Código Pago Trámite" :value="cpt.codigo" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Estado" :value="cpt.estado" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Fecha Emisión" :value="formatDateText(cpt.fechaInicio)" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Fecha Pago" :value="formatDateText(cpt.fechaPago)" disabled></v-text-field>
        </v-flex>
      </template>
      <template v-if="propiedades.metodoPago==='PASARELA_PAGO' && solicitudPasarelaPago.metodoPago && solicitudPasarelaPago.metodoPago==='QR'">
        <v-flex lg12 class="subheading font-weight-medium py-0">QR pago trámite</v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Id QR" :value="qr.idQr" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Estado" :value="qr.estado" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Fecha Creacion" :value="formatDateText(qr.fechaCreacion)" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Monto Total" :value="qr.monto" disabled></v-text-field>
        </v-flex>
        <v-flex lg2 xs6>
          <v-text-field label="Fecha Pago" :value="formatDateText(qr.fechaPago)" disabled></v-text-field>
        </v-flex>
      </template>
      <!-- <v-flex lg3 xs6>
         <v-text-field label="Código Pago Trámite" :value="cpt.codigo" disabled></v-text-field>
      </v-flex>
      <v-flex lg3 xs6>
        <v-text-field label="Estado" :value="cpt.estado" disabled></v-text-field>
      </v-flex>
      <v-flex lg3 xs6>
        <v-text-field label="Fecha Emisión" :value="formatDateText(cpt.fechaInicio)" disabled></v-text-field>
      </v-flex>
      <v-flex lg3 xs6>
        <v-text-field label="Fecha Pago" :value="formatDateText(cpt.fechaPago)" disabled></v-text-field>
      </v-flex> -->
    </v-layout>
  </v-row>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
export default {
  mixins:[mixinDateTime],
  props:{
    propiedades:{
      type:Object,
      default:()=>{
        return{
          url:`${this.$apiUrl}abogado-ciudadano/metodo-pago-registro`,
          tipo:'REGISTRO',
          metodoPago:'BOLETA'
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      solicitudPasarelaPago:{
        metodoPago:'BOLETA'
      },
      cpt:null,
      qr:null,
      boletas:[]
    };
  },
  mounted(){
    this.getInformacion()
  },
  methods:{
    getInformacion(){
      this.loading = true
      this.$http.get(this.propiedades.url)
      .then(response=>{
        this.boletas = response.data.data.boletas
        this.solicitudPasarelaPago = response.data.data.solicitudPasarelaPago
        this.cpt = response.data.data.cpt
        this.qr=response.data.data.qr
        this.boletas = response.data.data.boletas||[]
        if(this.propiedades.tipo === 'REGISTRO'){
          if(['PASARELA_PAGO','CPT'].includes(this.propiedades.metodoPago)){
            let guardarMetodoPago = false
            if(this.propiedades.metodoPago==='PASARELA_PAGO'){
              if(['CREADO','SOLICITADO','EN_PROCESO'].includes(this.solicitudPasarelaPago.estado)){
                guardarMetodoPago = true
              }
            }
            if(this.propiedades.metodoPago==='CPT' && this.cpt && this.cpt.estado === 'CREADO'){
                guardarMetodoPago = true
            }
            if(guardarMetodoPago){
              this.$store.commit('abogado/setMetodoPago',response.data.data)
            }
          }
        }
        
      })
      setTimeout(()=>{
        this.loading = false
      },500)
    }
  }
};
</script>

